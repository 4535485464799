
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';


export default defineComponent({
  name: 'ShipToAddressesTable',
  components: {
    Dialog,
    Button,
    DataTable,
    Column,
    InputText,
  },
  props: {
    shipToAddresses: {
      type: Array,
      required: true
    },
    selectedAddress: {
      type: Object,
      required: false
    },
    selectedShipSeq: {
      type: String,
      required: false
    },
    hideNewAddressButton: {
      type: Boolean,
      default: false
    },
    showAllButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['openAddAddressDialog', 'onRowClick', 'onClose'],
  computed: {
    ...mapGetters({
      hideUseNewAddressButton: 'mrkControl/hideUseNewAddress',
      shippingAddress: "pos/getShippingAddress",
    }),
    showAddressButton() {
      return !this.hideNewAddressButton && !this.hideUseNewAddressButton
    }
  },
  data() {
    return {
      showDialog: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 25, 50],
      filters: {} as any,
    }
  },
  created() {
    this.resetFilters();
  },
  methods: {
    handleCloseModeOptionsDialog() {
      this.showDialog = false;
      this.$emit('onClose');
      this.resetFilters();
    },
    handleUseNewAddress() {
      this.$emit('openAddAddressDialog');
    },
    resetFilters() {
      this.filters = {
        ship_seq: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_name: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_city: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_state: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_zip: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_country: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_attn: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
    isAddressSelected (data: any) {
      if (this.selectedAddress) {
        if(data.ship_seq === this.selectedAddress.ship_seq){
          return true
        } else {
          return false
        }
      }
      if(this.selectedShipSeq){
        if(data.ship_seq === this.selectedShipSeq){
          return true
        } else {
          return false
        }
      }
      return false
    },
    handleRowName(row: any): string {
      const isAddressSelected = this.isAddressSelected(row)
      return isAddressSelected ? "row-selected" : ""
    },
    handleRowClick(row: any) {
      this.$emit('onRowClick', row);
    },
  }
})
