
import { defineComponent } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { mapGetters, mapActions } from "vuex";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "AddTaxDialog",
  components: {
    Button,
    Dialog,
    Dropdown,
    InputText,
  },

  emits: ["addTax"],
  created() {
    this.fetchSalesTaxCodes();
  },
  computed: {
    ...mapGetters({
      getSalesTaxCodes: "stax/getSalesTaxCodes",
    }),
  },
  methods: {
    ...mapActions({
      fetchSalesTaxCodes: "stax/fetchSalesTaxCodes",
    }),
    addRecord() {
      this.$emit("addTax", this.newTax);
      this.visible = false;
      this.newTax = {
        code: "",
        rate: "",
      };
    },
    onTaxCodeChange(event: any) {
      const taxCode = this.getSalesTaxCodes.find(
        (tax: any) => tax.id === event.value
      );
      if (taxCode) {
        this.newTax.rate = taxCode.rate;
      }
    },
    cancel() {
      this.visible = false;
      this.newTax = {
        code: "",
        rate: "",
      };
    },
  },

  data() {
    return {
      visible: false,
      newTax: {
        code: "",
        rate: "",
      },
    };
  },
});
