
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import CheckBox from 'primevue/checkbox';

export default defineComponent({
  name: 'AddCreditMemoDialog',
  components: {
    Button,
    Dialog,
    CheckBox,
    InputNumber,
    InputText,
  },

  emits: ['addCreditMemo'],

  methods: {
    addRecord() {
      this.$emit('addCreditMemo', this.newCreditMemo);
      this.visible = false;
      this.newCreditMemo = {
        memo: '',
        amount: 0,
        apply: ''
      }
    },

    cancel() {
      this.visible = false;
      this.newCreditMemo = {
        memo: '',
        amount: 0,
        apply: ''
      }
    },

  },

  data() {
    return {
      visible: false,
      newCreditMemo: {
        memo: '',
        amount: 0,
        apply: ''
      }
    };
  }
});
